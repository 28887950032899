import React from "react";
import Policies from "../components/sections/policies/Policies";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
const Privacy = () => {
  return (
    <>
      <Header />
      <Policies />
      <Footer />
    </>
  );
};

export default Privacy;
