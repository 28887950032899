import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import "./NotFoundSection.styles.css";

const NotFoundSection = () => {
  return (
    <section className="not-found flow-content">
      <p>Look like you're lost</p>
      <div className="sub-heading">404</div>
      <div className="not-found__btn">
        <Link to="/">
          <Button tag="a" color="primary" wideMobile>
            GO BACK
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default NotFoundSection;
