import React from "react";
import "./policies.styles.css";
const Terms = () => {
  return (
    <section className="policy-container flow-content">
      <h4>Terms of Service</h4>
      <p>
        This agreement is a legal agreement between you (hereinafter referred to
        as “You” or “Your”) and Reximob (Hereinafter referred to as “We”, “App”,
        “Company”, “Reximob”) regarding your downloading, installation, and the
        use of the App.
      </p>
      <p>
        Your downloading, installation, use of, and other access to the
        application are conditioned upon your acceptance and compliance with the
        terms and conditions of this license agreement. The company has the
        right to modify the terms of this agreement regarding the updates of the
        App. You can check the modified version of this Agreement from the
        application or the official website.
      </p>
      <p>
        You understand and agree that if you continuously use the application
        after the date on which these terms and conditions have changed, we will
        treat your use as an acceptance and compliance with the updated terms
        and conditions.
      </p>
      <h5>Intellectual Property Policy</h5>
      <ol>
        <li>
          The application is developed by Reximob independently. You acknowledge
          and agree that Reximob retains all copyrights, trademarks, patents,
          business secrets, including all intellectual property rights and other
          related rights, titles, and interests. The application and the related
          information including but not limited to written expression and
          combination, icons, decorative pictures, tables, colors, user
          interface, framework, related data, printing materials, or electronic
          documents, are protected by Copyright, Trademark, Patent, and relevant
          international agreements and other intellectual property laws and
          regulations.
        </li>
        <li>
          You may not (and you may not permit anyone else to) take actions
          directly or indirectly that infringes or may infringe intellectual
          property rights and related interests of the Company (including but
          not limited to exploiting, assigning, and referenced intellectual
          property rights above or permitting anyone else to do so). Reximob
          reserves the right to charge the tort liabilities.
        </li>
        <li>
          You may not (or entrust a third party), whether on non-profit purpose
          or not, reverse engineer, decompile or attempt to derive source code
          of the application, or create derivative works of product, services,
          plugins, extensions, compatibility, interconnection behaviors, etc.
          Reximob reserves the right to charge the tort liabilities, unless you
          have been granted by Reximob, in writing.
        </li>
      </ol>
      <h5>Scope of License</h5>
      <ol>
        <li>
          Reximob gives you a non-exclusive, non-assignable license to download,
          install and use the application. In no way can users use it for
          commercial use.
        </li>
        <li>
          You may not use the application in a way that may lead to damage,
          suspension, overloading, or quality reduction to the application (of
          server and internet-connected to the application), or in a way that
          may affect the product and services provided to any other users.
        </li>
        <li>
          You have precisely read and agree to the agreement and statements
          published by Reximob. You promise not to copy, grant a sub-license,
          share or sell the application or related services. You are fully
          responsible for any fees created by or arising from your own factors.
          Reximob does not assume any liability.
        </li>
        <li>
          Rights reserved. Reximob reserves all rights not expressly granted to
          you. You are required to obtain additional consent to use other
          software.
        </li>
      </ol>

      <h5>Notice to End Users</h5>
      <ol>
        <li>
          You agree to use the application in a manner permitted by this
          Agreement and are solely responsible for any breach of your
          obligations under national laws and regulations, related policies, or
          this Agreement and for the consequences (including the loss and
          damages to the application and any third-parties) of any such breach.
          You do not have the right to conduct the following behaviors
          (including but not limited to):
        </li>
        <ul>
          <li>
            Delete copyright information, content on the application, or other
            copies
          </li>
          <li>
            Reverse engineer, decompile or extract the source code of the
            application
          </li>
          <li>
            Conduct any behaviors that jeopardize the computer network security,
            including but not limited to: using unauthorized data or
            unauthorized access to server/account; entering a public network or
            other’s computer system to delete, modify, add stored information
            without permission; attempting to search, scan, test the system,
            internet leak, or other behaviors breaking internet security;
            attempt to interfere, damage the system or website running normally,
            deliver the malware or virus intentionally to damage the normal
            internet information service; forge (part of) names of TCP/IP
            package.
          </li>
          <li>
            Sell, rent, loan, deliver, transfer or sub-license the application
            and services, or related links, or benefit from using the software
            and the services, or barely benefit from the agreements, regardless
            of whether the above usage brings direct economic gain or pecuniary
            gain.
          </li>
          <li>
            Use the application and other services provided by Reximob in any
            illegal ways, for any illegal purposes, or in any other ways
            conflicting with this Agreement.
          </li>
          <li>
            Reximob reserves the right to terminate, fully or partially suspend,
            limit functional features of the user’s account, without prior
            notice, if the user has breached any of the terms of this Agreement.
          </li>
        </ul>
        <li>
          Reximob does not assume any liability for any loss of profits or loss
          of data arising from the special, malicious, indirect, or similar
          damages (but not limited to damages) due to your use of the
          application.
        </li>
      </ol>
      <h5>Privacy Policy</h5>
      <ol>
        <li>
          The use of the applications is also governed by Reximob’s Privacy
          Policy. Your privacy is important to us, We designed our Privacy
          Policy to make important disclosures about how we collect and use your
          information. We encourage you to read it carefully and use it to make
          informed decisions.
        </li>
      </ol>
      <h5>Limitations of Liabilities and Disclaimer</h5>
      <ol>
        <li>
          You acknowledge and agree that the application and its related
          services may have potential risks like service interruption, failure
          to respond to users’ requests, due to force majeure, mobile
          communication terminal virus or hacker attack, system instability,
          user physical location, phone power off, and other reasons concerning
          technology, telecommunication lines. In no event shall Reximob be
          liable for any risks stated above.
        </li>
        <li>
          Reximob is not liable for any losses to users arising out of
          telecommunication line breakdown, technical problems, internet, mobile
          communication terminal failures, system instability, and any other
          force majeure.
        </li>
        <li>
          Given business development and adjustment, Reximob reserves the right
          to amend or terminate services without prior notice to users at any
          time, and Reximob is not liable for users and any other third parties
          when executing this right.
        </li>
        <li>
          The application which is not officially released or authorized by
          Reximob and the derivative works of the application are illegal.
          User’s downloading installations, and using this software may lead to
          unexpected risks. Reximob is not liable for any legal liabilities,
          issues arising from it.
        </li>
        <li>
          You agree to the most extent under applicable law. Reximob owns other
          disclaimer rights not listed in this agreement.
        </li>
      </ol>
      <h5>Termination</h5>
      <ol>
        <li>
          You have the right to fully and permanently remove the application
          from your mobile device at any time and terminate this Agreement.
        </li>
        <li>
          Unless Reximob unilaterally terminates this Agreement or you terminate
          this agreement as above, this agreement and the terms (including the
          updated agreement following) will remain effective.
        </li>
        <li>
          Your right will be terminated automatically if you breach any
          obligations stated in this Agreement. Reximob and other third parties
          have on need to send your additional notice.
        </li>
        <li>
          The termination of this Agreement will not affect the obligations and
          liabilities users should assume before the termination.
        </li>
      </ol>
      <h5>Others</h5>
      <ol>
        <li>
          You agree that if Reximob does not exercise or enforce any legal right
          or regulations, this will not be taken to be a formal waiver of
          Reximob’s rights, and Reximob has the right to continuously exercise
          or enforce the right or regulation.
        </li>
        <li>
          Without written authorization from another side, the user may not
          assign or transfer the rights granted by this Agreement, or assign the
          designated responsibilities and obligations to others.
        </li>
        <li>
          If any court of law, having the jurisdiction to decide on this matter,
          rules that any provision of this Agreement is invalid, then that
          provision will be removed from the Agreement without affecting the
          rest of the terms. The remaining provisions of the Agreement will
          continue to be valid and enforceable.
        </li>
        <li>
          Reximob reserves the final interpretation fight on this Agreement.
        </li>
      </ol>
      <p>Effective Date: 01/02/2022 </p>
    </section>
  );
};

export default Terms;
