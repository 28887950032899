import React from "react";
import Terms from "../components/sections/policies/Terms";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
const Privacy = () => {
  return (
    <>
      <Header />
      <Terms />
      <Footer />
    </>
  );
};

export default Privacy;
